import { createRouter, createWebHashHistory } from 'vue-router'
import { dishRoutes } from '@/router/dish'
import { orderRoutes } from '@/router/order'
import { settingRoutes } from '@/router/setting'
import { staffRoutes } from '@/router/staff'
import { customerRoutes } from '@/router/customer'
import { memberRoutes } from '@/router/member'

import store from '@/store'
// import { bindKey, getInitCookieData } from '@/assets/common'
import { ElMessage } from 'element-plus'
import en from '../i18n/lang/en'
import zh from '../i18n/lang/zh'
import { checkLogin } from '@/assets/common'

const defaultChildrenManage = (auth) => {
  return [
    {
      path: '',
      name: auth + '.main',
      meta: { auth: true },
      component: () => import('../views/pages/manage/IndexPage.vue')
    },
    ...dishRoutes(auth, 'dish'),
    ...orderRoutes(auth, 'order'),
    ...settingRoutes(auth, 'setting'),
    ...staffRoutes(auth, 'staff'),
    ...customerRoutes(auth, 'customer'),
    ...memberRoutes(auth, 'member')
  ]
}

export const manageChildren = defaultChildrenManage('manage')

const authChildrenRoutes = (auth) => {
  return [
    {
      path: '',
      name: auth + '.signIn',
      meta: { auth: true },
      component: () => import('../views/pages/manage/auth/SignIn')
    },
    {
      path: '/resetPassword',
      name: auth + '.resetPassword',
      meta: { auth: true },
      component: () => import('../views/pages/manage/auth/ResetPassword')
    },
    {
      path: 'bindPassCode',
      name: auth + '.bindPassCode',
      component: () => import('../views/pages/manage/auth/BindPassCode.vue')
    }
  ]
}

const routes = [
  {
    path: '/',
    name: 'auth',
    component: () => import('../layouts/guest/BlankLayout'),
    children: authChildrenRoutes('auth')
  },
  {
    path: '/manage',
    name: 'manage',
    meta: { t: 'menu.home', viewRouter: true },
    component: () => import('../layouts/manage/DefaultLayout'),
    children: manageChildren
  },
  {
    path: '/join',
    name: 'join',
    meta: { jurisdiction: [2] },
    component: () => import('../views/pages/manage/join')
  },
  {
    path: '/bind-payment-platform',
    name: 'bindPaymentPlatform',
    component: () => import('../views/pages/manage/bindPaymentPlatform.vue')
  }
  // 临时-官网joinyemian
  // {
  //   path: '/join-us',
  //   name: 'joinUs',
  //   component: () => import('../views/pages/temporaryJoin.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
})

// 获取当前语言
function getLanguageStorage() {
  let language = store.getters['setting/language']
  return language === 'en' ? en : zh
}

// 校验权限
function checkAuth(path) {
  let menuList = store.getters['setting/menu_auth_list']
  return menuList.includes(path)
}

router.beforeEach((to, from, next) => {
  let lang = getLanguageStorage()
  // 白名单--不需要在此验证登录
  let whitePath = ['auth.signIn', 'join', 'auth.bindPassCode', 'bindPaymentPlatform']
  if (whitePath.includes(to.name)) {
    next()
    return
  }
  // 验证是否登录
  if (!checkLogin()) {
    ElMessage({
      message: lang.loginTimeout,
      type: 'error'
    })
    return next({
      name: 'auth.signIn'
    })
  }
  // 验证是否与支付平台绑（仅当付款方式为商家付款时）
  let bindPaymentStatus = store.getters['setting/bind_payment_status']
  let paymentType = store.getters['setting/user'].seller.paymentType
  // 未成功绑定支付平台
  if (!bindPaymentStatus && paymentType === 0) {
    ElMessage({
      message: lang.auth.bindPayment.toBind.tip,
      type: 'error'
    })
    return next({
      name: 'bindPaymentPlatform'
    })
  }
  // 验证是否有权限
  let isAdmin = store.getters['setting/is_admin']
  if (!isAdmin && !checkAuth(to.path)) {
    ElMessage({
      message: lang.noPermission,
      type: 'error'
    })
    return next({
      name: 'manage.main'
    })
  }
  next()
})

export default router
