import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import "@/assets/scss/global.scss"

// Library Components
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue3-apexcharts'
import BootstrapVue3 from 'bootstrap-vue-3'
import CounterUp from 'vue3-autocounter'
import 'aos/dist/aos.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import i18n from '@/i18n'
import meta_data from '@/assets/meta_data.json'

// Custom Components & Directives
import globalComponent from './plugins/global-components'
import globalDirective from './plugins/global-directive'
import globalMixin from './plugins/global-mixin'
import { formatMoney } from '@/assets/filters'
import { UTCDate, UTCString } from '@/assets/common'

require('waypoints/lib/noframework.waypoints.min')

// 权限指令
import auth from './assets/auth'

const app = createApp(App)

app.use(store).use(router)
// Library Components
app.use(VueSweetalert2)
app.use(VueApexCharts)
app.use(BootstrapVue3)
app.use(i18n)
app.use(auth)
app.component('counter-up', CounterUp)

// Custom Components & Directives
app.use(globalComponent)
app.use(globalDirective)
app.mixin(globalMixin)

app.config.globalProperties.UTCString = UTCString
app.config.globalProperties.UTCDate = UTCDate
app.config.globalProperties.formatMoney = (value, decimals, dec_point, thousands_sep, roundTag) => {
  return formatMoney(value, decimals, dec_point, thousands_sep, roundTag)
}
app.config.globalProperties.formatPhone = (value, areaNumber, areaType) => {
  if (!value) return ''
  let tel_no = value
  const detail_metadata = meta_data[areaType][4]
  let format_metadata = []
  for (let i = 0; i < detail_metadata.length; i++) {
    const detail_metadata_regx = detail_metadata[i][2][detail_metadata[i][2].length - 1]
    if (0 !== tel_no.search(detail_metadata_regx) || !RegExp('^(?:' + detail_metadata[i][0] + ')$').test(tel_no)) continue

    format_metadata = detail_metadata[i]
    break
  }
  if (format_metadata.length > 0) {
    const gd = /(\$\d)/
    //National
    tel_no = tel_no.replace(new RegExp(format_metadata[0]), format_metadata[3] ? format_metadata[1].replace(gd, format_metadata[3]) : format_metadata[1])
    //International
    tel_no = tel_no.replace(new RegExp(format_metadata[0]), format_metadata[5] || format_metadata[1])
  }
  let result = ''
  if (areaNumber) {
    result += '+' + areaNumber + ' '
  }

  return result + tel_no
}

Date.prototype.format = function (fmt) {
  const o = {
    'M+': this.getMonth() + 1, //月份
    'd+': this.getDate(), //日
    'h+': this.getHours(), //小时
    'm+': this.getMinutes(), //分
    's+': this.getSeconds(), //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds() //毫秒
  }

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }

  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
    }
  }

  return fmt
}

app.mount('#app')

export default app
