<template>
  <div class="body">
    <el-config-provider :locale="locale">
      <router-view />
    </el-config-provider>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import '@/plugins/styles'
import store from '@/store'
import {ElConfigProvider} from "element-plus"
import en from "element-plus/lib/locale/lang/en"
import zhCn from "element-plus/lib/locale/lang/zh-cn"

export default {
  name: 'App',
  components: { ElConfigProvider },
  data() {
    const store = useStore()
    store.dispatch('setting/setSetting')
    return {
      sidebarType: computed(() => store.getters['setting/sidebar_type']),
      locale: en
    }
  },
  mounted() {
    this.changeLanguage(store.getters["setting/language"])
    window.addEventListener('resize', this.resizePlugin)
    setTimeout(() => {
      this.resizePlugin()
    }, 200)
  },
  unmounted() {
    window.removeEventListener('resize', this.resizePlugin)
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang
      store.commit('setting/language', lang);
      switch (lang) {
        case "zh":
          this.locale = zhCn;
          break;
        case "en":
          this.locale = en;
          break;
      }
    },
    resizePlugin() {
      const sidebarResponsive = document.querySelector('[data-sidebar="responsive"]')
      if (window.innerWidth < 1025) {
        if (sidebarResponsive !== null) {
          if (!sidebarResponsive.classList.contains('sidebar-mini')) {
            sidebarResponsive.classList.add('on-resize')
            store.dispatch('setting/sidebar_type', [...this.sidebarType, 'sidebar-mini'])
          }
        }
      } else {
        if (sidebarResponsive !== null) {
          if (sidebarResponsive.classList.contains('sidebar-mini') && sidebarResponsive.classList.contains('on-resize')) {
            sidebarResponsive.classList.remove('on-resize')
            store.dispatch(
              'setting/sidebar_type',
              this.sidebarType.value.filter((item) => item !== 'sidebar-mini')
            )
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/custom-vue/scss/styles.scss';
.is-href {
  cursor: pointer;
}

.table-height {
  height: 660px;
}

.table-content {
  height: 660px;
  position: relative;
}

.text-overflow-hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.remarks-table {
  width: 250px !important;
}

.required:before {
  content: '*';
  color: red;
}

@media (min-width: 1200px) {
  .modal-lg,
  .modal-xl {
    max-width: 1000px;
  }
}

@media (min-width: 1600px) {
  .modal-lg,
  .modal-xl {
    max-width: 1400px;
  }
}
</style>
